// eslint-disable-next-line import/prefer-default-export
export function getSortedOptions(tableOptions, sortingMap) {
  const { sortBy, sortDesc } = tableOptions;
  let currentSortBy = sortBy;
  let currentSortDesc = sortDesc;

  if (sortingMap.fields.includes(sortBy[0])) {
    currentSortBy = sortingMap[sortBy[0]];
    const count = sortingMap[sortBy[0]].length;
    currentSortDesc = Array(count).fill(sortDesc[0]);
  }

  return { sortBy: currentSortBy, sortDesc: currentSortDesc };
}

export const deviceMultiplierMap = {
  '1.0.12.7.0.255': {
    read: 1,
    store: 1,
  },
  '1.0.14.7.0.255': {
    read: 1,
    store: 0.01,
  },
  '1.0.11.7.0.255': {
    read: 1,
    store: 1,
  },
  '1.0.1.8.0.255': {
    read: 1,
    store: 1,
  },
};

export function convertDateObjectToString(dateObject) {
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');
  const hours = dateObject.getHours().toString().padStart(2, '0');
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');
  const seconds = dateObject.getSeconds().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}
