// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
import req from '@/plugins/requests';
import { deviceMultiplierMap } from '@/utils/utils';

const websocketStore = {
  namespaced: true,

  state: {
    socket: null,
    connectionStatus: false,
    reconnectTimeout: 10000,
    meteringDeviceData: [],
    meteringDeviceParams: '',
  },

  mutations: {
    OPEN_SOCKET(state, socket) {
      state.socket = socket;
    },

    CLOSE_SOCKET(state) {
      if (state.socket) {
        state.socket.close();
      }
      state.socket = null;
    },

    SET_CONNECTION_STATUS(state, value) {
      state.connectionStatus = value;
    },

    SET_METERING_DEVICE_DATA(state, payload) {
      state.meteringDeviceData = payload;
    },

    SET_METERING_DEVICE_CHART(state, { code, chart }) {
      state.meteringDeviceData.find((item) => item.code === code)?.chart.shift();
      state.meteringDeviceData.find((item) => item.code === code)?.chart.push(chart);
    },

    RESET_METERING_DEVICE_DATA(state) {
      state.meteringDeviceData = [];
    },

    SET_METERING_DEVICE_PARAMS(state, payload) {
      state.meteringDeviceParams = payload;
    },
    RESET_METERING_DEVICE_PARAMS(state) {
      state.meteringDeviceParams = {};
    },
  },

  actions: {
    async connectSocket({ commit, state, dispatch }, { url, isReconnect = false }) {
      const { login, token, session } = store.getters['authStore/user'];
      const authUrl = `${url}/${login}/${session}/${token}`;
      const socket = new WebSocket(authUrl);

      socket.onopen = () => {
        if (!isReconnect) {
          commit('SET_CONNECTION_STATUS', true);
        }

        commit('OPEN_SOCKET', socket);
      };

      socket.onmessage = ({ data }) => {
        const message = JSON.parse(data);

        switch (message.cmd) {
          case 'login': {
            console.log('Socket is opened and ready to interact.');
            break;
          }
          case 'msg': {
            if (Object.hasOwn(message.dat, 'text')) {
              store.commit('notificationMessages', {
                message: message.dat.text,
                type: 'success',
              });
            }
            break;
          }
          case 'meter': {
            const params = message.prm.split('/');

            // cri0 - check object has property
            if (!Object.hasOwn(message.dat, 'value')) {
              message.dat.value = 0.0;
            }

            // data about device multiplier
            const [, , , cosem] = params;
            const multiplier = deviceMultiplierMap[cosem];

            const date = new Date();
            const time = new Intl.DateTimeFormat('ru', {
              timeStyle: 'medium',
            }).format(date);

            const yAxisData = multiplier !== undefined
                      && multiplier.store !== undefined
                      && multiplier.store !== null
              ? message.dat.value * multiplier.store
              : message.dat.value;

            const payload = {
              code: params[params.length - 1],
              chart: {
                x: `${time}`,
                y: yAxisData,
              },
            };

            commit('SET_METERING_DEVICE_CHART', payload);
            break;
          }
          default: {
            console.log('unknown type of message: ', message.cmd);
          }
        }
      };

      socket.onclose = () => {
        if (state.connectionStatus) {
          setTimeout(() => {
            dispatch('connectSocket', { url, isReconnect: true });
          }, state.reconnectTimeout);
        }
      };
    },

    disconnectSocket({ commit }) {
      commit('SET_CONNECTION_STATUS', false);
      commit('CLOSE_SOCKET');
    },

    async subscribeMeteringDevice({ state, commit }, { group, id }) {
      commit('SET_METERING_DEVICE_PARAMS', {
        id,
        group,
        prm: `meter/g${group}/m${id}/#`,
      });

      if (state.socket) {
        console.log(`subscribeMeteringDevice ${state.meteringDeviceParams.prm}`);

        const response = await req.post('deviceGraphData', { id });

        if (response.code === 20) {
          const deviceGraphData = response.data.map((item) => ({
            serial: item.serial,
            code: item.type_cosem,
            title: item.type_name,
            label: item.unit_short,
            backgroundColor: item.type_color,
          }));

          const defaultChart = [];
          const defaultChartData = {
            y: 0,
            x: '',
          };

          for (let i = 0; i < 30; i += 1) {
            defaultChart.push(defaultChartData);
          }

          commit(
            'SET_METERING_DEVICE_DATA',
            deviceGraphData.map((item) => ({
              ...item,
              chart: [...defaultChart],
            })),
          );

          state.socket.send(
            JSON.stringify({
              cmd: 'sub',
              prm: state.meteringDeviceParams.prm,
              dat: {},
            }),
          );
        }
      }
    },

    unsubscribeMeteringDevice({ state, commit }) {
      if (state.socket) {
        console.log(`unsubscribeMeteringDevice ${state.meteringDeviceParams.prm}`);

        state.socket.send(
          JSON.stringify({
            cmd: 'unsub',
            prm: state.meteringDeviceParams.prm,
            dat: {},
          }),
        );
      }

      commit('RESET_METERING_DEVICE_DATA');
      commit('RESET_METERING_DEVICE_PARAMS');
    },
  },
};

export default websocketStore;
